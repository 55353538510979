<template>
	<div dir="auto">
		<b-card no-body>
			<b-card-header>
				<div class="flex-fill">
					<div class="d-flex justify-content-between align-items-center">
						<h4 class="mb-0">
							احصائيات الاشراف في المواقع عبر تطبيق الموبايل ( خاصة بجمع البيانات من المواقع RMS ونظام المعلومات MIS)
						</h4>
						<loading-btn :loading="loading" variant="primary" @click="exportExcel">Export Excel</loading-btn>
					</div>
					<hr />
					<div class="d-flex align-items-end" style="max-width: 450px;">
						<div class="flex-fill">
							<div>
								<label style="direction: rtl !important">اختر التمويل</label>
								<v-select
									id="technician"
									v-model="donorId"
									:searchable="false"
									:options="getDonors"
									:reduce="(o) => o.id"
								/>
							</div>
						</div>
						<div class="mr-2">
							<b-button variant="primary" @click="loadData">Apply</b-button>
						</div>
					</div>
				</div>
			</b-card-header>
			<div dir="rtl" align="left" class="position-relative table-responsive pb-2">
				<table id="workersSummaryTable" class=" table b-table table-bordered">
					<thead>
						<tr>
							<th rowspan="2">المحافظات</th>
							<th colspan="6">بيانات المشاريع في الخطة</th>
							<th colspan="5">بيانات المشاريع من الواقع</th>
							<th rowspan="2">متوسط سعر الصرف للدولار</th>
						</tr>
						<tr>
							<th>عدد</th>
							<th>تكلفة</th>
							<th>المنصرف المالي</th>
							<th>عدد العمالة</th>
							<th>تكلفة العمالة</th>
							<th>فرص العمل</th>
							<th>عدد</th>
							<th>المنصرف المالي</th>
							<th>عدد العمالة</th>
							<th>تكلفة العمالة</th>
							<th>فرص العمل</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, i) in items" :key="i">
							<td>
								<a class="text-primary font-weight-bold" @click="openDetails(item)">
									{{ item.city }}
								</a>
							</td>
							<td>{{ item.projects }}</td>
							<td>{{ formatMoney(item.cost, true) }}</td>
							<td>{{ formatMoney(item.disbursements, true) }}</td>
							<td>{{ item.total_labors }}</td>
							<td>{{ formatMoney(item.laber_cost, true) }}</td>
							<td>{{ item.total_working_days }}</td>
							<td>{{ item.projects }}</td>
							<td>{{ formatMoney(item.items_cost, true) }}</td>
							<td>{{ item.workers }}</td>
							<td>{{ formatMoney(item.workers_cost, true) }}</td>
							<td>{{ item.worker_opp }}</td>
							<td>{{ formatMoney(item.dollar_rate) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardHeader, BButton } from 'bootstrap-vue';
import api from '@/utils/api';
import vSelect from 'vue-select';
import { formatMoney } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import LoadingBtn from '@/components/LoadingBtn.vue';

export default {
	name: 'ProjectsInsights',

	components: { BCard, BCardHeader, BButton, vSelect, LoadingBtn },

	data: () => ({
		items: [],
		donors: [],
		donorId: '',

		loading: false,
	}),

	computed: {
		...mapGetters('attributes', ['getDonors']),
	},

	async created() {
		this.loadData();
	},

	methods: {
		formatMoney,

		async loadData() {
			const params = new URLSearchParams({ donorId: this.donorId ?? '' });
			const { data } = await api.call({
				path: `/reports/project-insight?${params}`,
				method: 'GET',
			});

			this.items = data;
		},

		openDetails(item) {
			this.$router.push({
				name: 'projects-insight-details',
				query: {
					donorId: this.donorId,
					city: item.city,
				},
			});
		},

		exportExcel() {
			this.loading = true;

			api.getFile(`reports/project-insight/export`)
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						}),
					);

					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'project-items');
					document.body.appendChild(link);
					link.click();
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
tr > th {
	vertical-align: middle;
	text-align: center;
}
</style>
